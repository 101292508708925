<script>
import { SuawInputGroup } from "@/components";
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";

export default {
  name: "OrganizerFAQ",
  components: {
    SuawInputGroup,
    ResourceFrame
  }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
      <h1 class="suaw-organizer-resource-heading">Organizer <b>FAQ</b></h1>
      <h2 class="suaw-organizer-resource-subtitle">In-Person Events</h2>
      <h2 class="suaw-organizer-resource-subtitle">How do I find a place to host an in-person event?</h2>
      <p class="suaw-paragraph">
        Search online for local cafes, coffee shops, bookstores, and public libraries in your area. Feel free to visit ahead of time to get a sense of the space. Check the availability of parking, WiFi, and outlets for laptops. Ensure the establishment is open at a convenient time so you can host your events at the same time each week. Keep in mind that venues should be public and free for writers, though it is okay for you and your writers to support the venue by purchasing a beverage or a snack if they are a cafe or coffee shop.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What if no one shows up?</h2>
      <p class="suaw-paragraph">
        We recommend making the commitment to yourself to get out of the house and write once a week. If others show up, that's a bonus! Either way, you're developing your writing habit and getting words on the page. Whether it's for a group of a dozen writers or just for yourself, that's exactly what a Shut Up & Write! event is about. In fact, our founder, Rennie Saunders, wrote at a cafe by himself several times at the start of our organization!
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Do I have to make reservations?</h2>
      <p class="suaw-paragraph">
        We recommend calling ahead at your venue of choice or even visiting to get a sense of how busy it is and whether the owner of the establishment requires a reservation.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">How do I move my in-person event online?</h2>
      <p class="suaw-paragraph">
        Whether you want to move your in-person event online temporarily or permanently, please contact <a href='mailto:organizersupport@shutupwrite.com'>organizersupport@shutupwrite.com</a> to get help and further training.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What if I'm moving?</h2>
      <p class="suaw-paragraph">
        Your event can move with you! Continuing to host Shut Up & Writes! in your new location is a great way to connect with others in your new community. Just create a new local event on shutupwrite.com and be sure to promote your event in your new location. Let your old group know that you'll be leaving, and if possible, recruit a new organizer to take over your old event.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Online Events</h2>
      <h2 class="suaw-organizer-resource-subtitle">What equipment do I need to host an online event?</h2>
      <p class="suaw-paragraph">
        Our online events are hosted via Zoom or similar video conferencing software. In order to use Zoom, you'll need a computer capable of running Zoom, a stable internet connection, and a Zoom account. Since free Zoom accounts only allow for a 40 minute meeting duration, we ask that you purchase a paid Zoom account so that you can host an online event using Shut Up & Write's hour-long format.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What is a featured online event?</h2>
      <p class="suaw-paragraph">
        We think these events are a great starting place for people to get a feel for our online format. These events are 90 minutes, with an hour of dedicated writing time and some optional socializing afterwards.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What Zoom settings should I use for my online event?</h2>
      <p class="suaw-paragraph">
        During the "Shut Up & Write!" phase of your event, default to a Mute setting for all of your attendees in order to maintain a quiet writing environment. Make sure to review our <router-link :to="{ name: 'OrganizerHandbook' }">Organizer Handbook</router-link>, which has more information on specific Zoom settings.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">General Questions</h2>
      <h2 class="suaw-organizer-resource-subtitle">What do I get out of hosting Shut Up & Writes?</h2>
      <p class="suaw-paragraph">
        First, it's a great way to commit to your writing. It's also a good way to connect with other writers, not to mention a lot of fun! Organizers make a real difference in their communities, and we are here to support you every step of the way.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What time should I pick for my event?</h2>
      <p class="suaw-paragraph">
        Pick a day of the week and a time of day that works best for your own schedule! This way, hosting your event will be sustainable for you in the long run.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">How do I promote my event?</h2>
      <p class="suaw-paragraph">
        Print out flyers to leave at your chosen venue and around town. For online events, post on social media and ask friends to spread the word!
      </p>
      <h2 class="suaw-organizer-resource-subtitle">How do I find a co-host?</h2>
      <p class="suaw-paragraph">
        Having a co-host is a great way to have ongoing support at your event. Try asking on social media or at the end of your event to find potential co-hosts.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What if I have to cancel?</h2>
      <p class="suaw-paragraph">
        Finding a co-host is a great way to have a backup in case you can't make it. You can find one by asking at your event or by checking with local friends and acquaintances. But if you do have to cancel, whether it's last-minute, with advance notice, or permanently, please be sure to update your event on its Event Page so that your writers are in the loop.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What if I'm traveling?</h2>
      <p class="suaw-paragraph">
        If you're traveling, consider asking a regular attendee to take over hosting duties until you're back. If you're unable to find a co-host, you can host your event in your new location or simply cancel the sessions you're unable to lead until your return.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">How do I get people to socialize?</h2>
      <p class="suaw-paragraph">
        Asking about each person's writing is a great way to get people talking. But keep in mind that some writers may be introverted or shy, which is okay! Shut Up & Write! is for everyone, whether they prefer to socialize or not.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What if writers have questions?</h2>
      <p class="suaw-paragraph">
        Anyone in our community can reach out to our Support team at <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a> for help with any questions. Our help desk is available Monday through Friday, 9am - 5pm Pacific time.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Can I host a Shut Up & Write! event only for a specific kind of writer?</h2>
      <p class="suaw-paragraph">
        Shut Up & Write! events allow writers of all genres, skill levels, and stages of the writing process to support each other (not to mention ages, genders, etc.) We love that you want to support a specific kind of writer, but it is our belief that being as inclusive as possible is actually the best way to be supportive.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">What if someone is behaving disruptively at my event?</h2>
      <p class="suaw-paragraph">
        Please refer to the guidelines mentioned above in our In-Person and Online sections, and reach out to <a href='mailto:organizersupport@shutupwrite.com'>organizersupport@shutupwrite.com</a>. All community members must abide by our <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link>. In the event that you witness illegal behavior, please reach out to your local authorities. Our help desk is available Monday through Friday, 9am - 5pm Pacific time.
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}

.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
